<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ $t("createNewService") }}</h1>
    </div>
    <div class="servicesAddPage__steps">
      <div class="servicesAddPage__step servicesAddPage__step-active">
        1. {{ $t("Description") }}
      </div>
      <div
        class="servicesAddPage__step"
        :class="{ 'servicesAddPage__step-active': stepTwo }"
      >
        2. {{ $t("Thumbnail") }}
      </div>
    </div>
    <div class="servicesAddPage">
      <div v-if="!stepTwo">
        <div class="d-flex justify-content-center">
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.english }"
            @click="languageChange('english')"
          >
            {{ $t("French") }}
          </div>
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.french }"
            @click="languageChange('french')"
          >
            {{ $t("English") }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <base-input
            v-show="language.english"
            v-model="serviceDescriptions.englishServiceName"
            class="servicesAddPage__input"
            :error="v$.serviceDescriptions.englishServiceName.$error"
            :error-message="v$.serviceDescriptions.englishServiceName.$errors"
          >
            {{ $t("ServiceName") }}*
          </base-input>
          <base-input
            v-show="language.french"
            v-model="serviceDescriptions.frenchServiceName"
            class="servicesAddPage__input"
            :error="v$.serviceDescriptions.frenchServiceName.$error"
            :error-message="v$.serviceDescriptions.frenchServiceName.$errors"
          >
            {{ $t("ServiceName") }}*
          </base-input>
        </div>
        <div class="servicesAddPage__titleContainer">
          <div class="servicesAddPage__title">{{ $t("shortDescription") }}</div>
        </div>
        <div class="servicesAddPage__shortDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.shortDescriptionValue.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.shortDescriptionValue.french"
            ></ckeditor>
          </div>
        </div>
        <div class="servicesAddPage__titleContainer">
          <div class="servicesAddPage__title">{{ $t("fullDescription") }}</div>
        </div>
        <div class="servicesAddPage__fullDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.fullDescriptionValue.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.fullDescriptionValue.french"
            ></ckeditor>
          </div>
        </div>
      </div>
      <div v-show="stepTwo">
        <drag-and-drop ref="thumbnail" v-model="thumbnail" />
      </div>
      <div
        class="servicesAddPage__footer"
        :class="{ 'justify-content-between': stepTwo && thumbnail }"
      >
        <base-button
          v-if="stepTwo && thumbnail"
          class="popup__remove-logo-btn"
          @click="removeImage()"
        >
          {{ $t("RemoveThumbnail") }}
        </base-button>
        <div class="d-flex align-items-center">
          <div
            v-if="stepTwo"
            @click="stepTwo = false"
            class="servicesAddPage__cancel"
          >
            {{ $t("back") }}
          </div>
          <base-button v-if="!stepTwo" @click="nextStep()"
            >{{ $t("nextStep") }}
          </base-button>
          <base-button
            v-if="stepTwo"
            :is-loaded="imageIsLoaded"
            @click="createServices()"
          >
            {{ $t("submit") }}
          </base-button>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseInput from "@/components/UI/inputs/BaseInput";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BaseButton from "@/components/UI/buttons/BaseButton";
import DragAndDrop from "@/components/elements/DragAndDrop";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";

export default {
  name: "ServicesAddPage",
  components: {
    HorizontalArrow,
    DragAndDrop,
    BaseButton,
    BaseInput,
    MainLayout,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "undo",
            "redo",
          ],
        },
      },
      stepTwo: false,
      haveChanges: true,
      thumbnail: null,
      language: {
        english: false,
        french: true,
      },
      serviceDescriptions: {
        shortDescriptionValue: {
          english: null,
          french: null,
        },
        fullDescriptionValue: {
          english: null,
          french: null,
        },
        englishServiceName: null,
        frenchServiceName: null,
      },
      backupValue: {},
    };
  },
  validations() {
    return {
      serviceDescriptions: {
        englishServiceName: { required },
        frenchServiceName: { required },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        from: from.href,
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  mounted() {
    this.backupValue = { ...this.serviceDescriptions };
  },
  methods: {
    removeImage() {
      this.thumbnail = null;
      this.$refs.thumbnail.remove();
    },
    nextStep() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.stepTwo = true;
    },
    createServices() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.haveChanges = false;
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      this.$store.commit("SET_LOADED_STATE", true);
      this.$http
        .post("/services", {
          image: this.thumbnail,
          translations: [
            {
              language_id: 1,
              name: this.serviceDescriptions.englishServiceName,
              short_description:
                this.serviceDescriptions.shortDescriptionValue.english,
              description:
                this.serviceDescriptions.fullDescriptionValue.english,
            },
            {
              language_id: 2,
              name: this.serviceDescriptions.frenchServiceName,
              short_description:
                this.serviceDescriptions.shortDescriptionValue.french,
              description: this.serviceDescriptions.fullDescriptionValue.french,
            },
          ],
        })
        .then(() => {
          this.haveChanges = true;
          this.$store.commit("SET_LOADED_STATE", false);
          this.toast.success(this.$t("successfulCreateService"));
          this.$router.push("/services");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.haveChanges = false;
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    languageChange(language) {
      for (let i in this.language) {
        this.language[i] = i !== language;
      }
    },
  },
  computed: {
    ...mapGetters({
      imageIsLoaded: "LOADED_STATE",
      popupsInformation: "STATE_POPUP",
    }),
    haveValues() {
      return this.serviceDescriptions;
    },
  },
  watch: {
    serviceDescriptions: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.backupValue) === JSON.stringify(newCount);
      },
    },
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.haveChanges = true;
          this.createServices();
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
      },
    },
  },
};
</script>

<style></style>
